import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import dataHooks from '../../data-hooks';
import { CloseModalPayload, OpenModalPayload } from '../../../../state/session/session.actions.types';
import { Modals, RouteUrls } from '../../../../core/constants';
import SubMenuView from './SubMenuView';
import styles from './MenuView.scss';
import MenuViewEmptyState from '../MenuViewEmptyState';
import StickyNavigationHeader from '../StickyNavigationHeader';
import FloatingCartButton from '../FloatingCartButton';
import NotificationBar from '../NotificationBar';
import DispatchSummary from '../DispatchSummary';
import MenuPicker from '../MenuPicker';
import {
  BusinessNotifications,
  DisplayableMenu,
  MenuDisplayOptions,
  Restaurant,
  AlignmentOptions,
} from '@wix/restaurants-client-logic';
import NoBusinessAddressNotification from '../NoBusinessAddressNotification';
import { scrollToMenuOrSection } from '../Scroller/scrollingUtils';
import LocationHeader from '../LocationHeader';
import { ClickOnMenuItemPayload } from '../../../../state/cart/cart.actions.types';
import { useAppSelector } from '../../../../core/hooks/redux';
import useMinOrderPriceDetails from '../../../../core/hooks/useMinOrderPriceDetails';
import { LocationsWithNotification } from '../../../../state/selectors/locationsSelector';
import { useBi, useExperiments } from 'yoshi-flow-editor-runtime';
import MediaQueryWrapper from '../MediaQueryWrapper/MediaQueryWrapper';
import { useStyles } from 'yoshi-flow-editor-runtime/tpa-settings/react';
import stylesParams from '../../stylesParams';
import { hasItemImages } from './MenuView.utils';

export interface MenuViewProps {
  displayableMenu: DisplayableMenu;
  orderItemsCount: number;
  isMobile?: boolean;
  siteIsTemplate?: boolean;
  layout?: 'side-by-side' | 'card';
  dishCardAlignment?: AlignmentOptions;
  menuHeaderAlignment?: AlignmentOptions;
  menuDisplayOptions?: MenuDisplayOptions;
  isRTL?: boolean;
  isCartModalOpen?: boolean;
  openModal: (payload: OpenModalPayload) => void;
  closeModal: (payload: CloseModalPayload) => void;
  notification?: BusinessNotifications;
  restaurant: Restaurant;
  locations: LocationsWithNotification;
  selectAddressOnDishClick?: boolean;
  isLocationPicked: boolean;
  clickOnMenuItem: (payload: ClickOnMenuItemPayload) => void;
  isRestaurantTakingOrders: boolean;
}

function convertSlugToEntityId(displayableMenu: DisplayableMenu, menuSlug: string, sectionSlug?: string) {
  const displayableSubMenu = displayableMenu.find((sm) => sm.slug === menuSlug);
  const displayableSection = displayableSubMenu?.sections.find((s) => s.slug === `${menuSlug}/${sectionSlug}`);
  return displayableSection?.id || displayableSubMenu?.id;
}

const MenuView: React.FC<MenuViewProps> = ({
  displayableMenu,
  orderItemsCount,
  isMobile,
  layout,
  dishCardAlignment,
  menuHeaderAlignment,
  menuDisplayOptions,
  isRTL,
  isCartModalOpen,
  notification,
  openModal,
  closeModal,
  restaurant,
  locations,
  selectAddressOnDishClick,
  clickOnMenuItem,
  isLocationPicked,
  siteIsTemplate,
  isRestaurantTakingOrders,
}) => {
  const match = useRouteMatch<{ subRoute: string; sectionSlug: string }>();
  const isMultiLocation = useAppSelector((state) => state.session.isMultiLocation);
  const { displayableMinOrderPrice } = useMinOrderPriceDetails();
  const { experiments } = useExperiments();
  const templatesDemoExperimentEnabled = experiments.enabled('specs.restaurants.templatesDemo');
  const tpaStyles = useStyles();
  const isEditorX = tpaStyles.get(stylesParams.responsive);
  const history = useHistory();
  const biLogger = useBi();
  const shouldOpenDispatchSettingsOnPageLoad = experiments.enabled('specs.restaurants.ML-open-dispatch-settings-modal-on-olo-page-load');

  useEffect(() => {
    if (match.params.subRoute === 'cart' && !isCartModalOpen) {
      openModal({ modal: Modals.CART_MODAL, redirectOnClose: '/' });
    } else if (match.params.subRoute !== 'cart' && isCartModalOpen) {
      closeModal({ modal: Modals.CART_MODAL });
    }

    if (match.params.subRoute && match.params.subRoute !== 'cart') {
      const entityId = convertSlugToEntityId(displayableMenu, match.params.subRoute, match.params.sectionSlug);
      if (entityId) {
        scrollToMenuOrSection(entityId, isMobile);
      }
    }
  }, [
    isCartModalOpen,
    openModal,
    closeModal,
    match.params.subRoute,
    match.params.sectionSlug,
    isMobile,
    displayableMenu,
  ]);

  function openDispatchSettingsOnPageLoad() {
    if (selectAddressOnDishClick && isRestaurantTakingOrders) {
      history.push(RouteUrls.DISPATCH_SETTINGS_MODAL);
      biLogger.openDispatchSettings({ openReason: 'olo page load ML' });
    }
  }

  useEffect(() => {
    if (shouldOpenDispatchSettingsOnPageLoad) {
      openDispatchSettingsOnPageLoad();
    }
  }, []);

  function shouldRenderDispatchSummary() {
    let shouldRender =
      notification !== BusinessNotifications.RestaurantClosed && notification !== BusinessNotifications.OlOunavailable;

    if (isMultiLocation && !shouldRender) {
      shouldRender = locations.some(
        ({ notification: locationNotification }) =>
          locationNotification !== BusinessNotifications.RestaurantClosed &&
          locationNotification !== BusinessNotifications.OlOunavailable,
      );
    }
    return shouldRender;
  }

  function shouldRenderLocationHeader() {
    return isMultiLocation && isLocationPicked;
  }

  function shouldRemoveNotificationBar() {
    return siteIsTemplate && templatesDemoExperimentEnabled;
  }

  const hasMenus = displayableMenu.length > 0;
  return (
    <div id={dataHooks.menuViewWrapper} data-hook={dataHooks.menuViewWrapper}>
      <NoBusinessAddressNotification />
      {!shouldRemoveNotificationBar() ? <NotificationBar /> : null}
      {shouldRenderDispatchSummary() ? <DispatchSummary /> : null}
      {shouldRenderLocationHeader() ? (
        <LocationHeader
          name={restaurant.locationName}
          locale={restaurant.locale}
          address={restaurant.address.formatted}
          minOrderPrice={displayableMinOrderPrice}
        />
      ) : null}
      {isEditorX ? (
        <MediaQueryWrapper desktopOnly className={styles.stickyEditorx}>
          <StickyNavigationHeader />
        </MediaQueryWrapper>
      ) : (
        !isMobile && <StickyNavigationHeader />
      )}
      {isEditorX
        ? displayableMenu.length > 1 && (
            <MediaQueryWrapper mobileOnly>
              <MenuPicker menus={displayableMenu} />
            </MediaQueryWrapper>
          )
        : isMobile && displayableMenu.length > 1 && <MenuPicker menus={displayableMenu} />}
      <div className={styles.wrapper} data-hook={dataHooks.menuView}>
        {!hasMenus && <MenuViewEmptyState />}

        {displayableMenu.map((displayableSubMenu) => (
          <SubMenuView
            clickOnMenuItem={clickOnMenuItem}
            key={displayableSubMenu.id}
            displayableSubMenu={displayableSubMenu}
            isMobile={isMobile}
            layout={layout}
            dishCardAlignment={dishCardAlignment}
            menuHeaderAlignment={menuHeaderAlignment}
            menuDisplayOptions={menuDisplayOptions}
            isRTL={isRTL}
            selectAddressOnDishClick={selectAddressOnDishClick && isRestaurantTakingOrders}
            hasItemImages={hasItemImages(displayableMenu)}
          />
        ))}
      </div>
      {isEditorX
        ? orderItemsCount > 0 && (
            <MediaQueryWrapper mobileOnly>
              <FloatingCartButton />
            </MediaQueryWrapper>
          )
        : isMobile && orderItemsCount > 0 && <FloatingCartButton />}
    </div>
  );
};

MenuView.displayName = 'MenuView';

export default MenuView;
